@import "../../index.css";

.card-container {
  min-height: 300px;
  width: 67dvw;
  gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding-inline: 1em;
  padding-block: 2em;
  margin-block: 2em;
  background: linear-gradient(
    to right,
    #feda75,
    #fa7e1e,
    #d62976,
    #962fbf,
    #4f5bd5
  );
}

.card-container:hover {
  outline: 2px solid var(--color-black);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ig-container {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin-left: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(254, 218, 117, 0.7);
  box-shadow: 5px black;
}

.ig {
  width: 48px;
  height: 48px;
}

.title {
  color: var(--color-white);
}

.account {
  font-weight: 800;
}

.content {
  color: gainsboro;
}

.button {
  padding-inline: 2em;
  padding-block: 0.7em;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  border: solid 0.5px black;
}

.button a {
  text-decoration: none;
  color: var(--color-black);
}

@media screen and (max-width: 1285px) {
  .content-container {
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .ig-container {
    margin-left: 0;
  }
}
