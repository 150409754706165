@import "../../index.css";

.about-us-container {
  background-color: var(--color-black);
  max-width: 100%;
  min-height: 100dvh;
  width: 100svw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-block: 2em;
}

.title {
  color: var(--color-white);
  margin-block: 1em;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3em;
  gap: 3em;
}

.comment-container {
  width: 350px;
  height: 400px;
  padding: 2em;
  border-radius: 10px;
  border: dotted 3px var(--color-grey);
}

.comment-container:hover {
  cursor: pointer;
  border: solid 3px var(--color-white);
}

.comment-container h5 {
  color: var(--color-white);
  padding-block: 2em;
}

.comment-container h4 {
  color: var(--color-grey);
}

@media screen and (max-width: 768px) {
  .comment-container {
    width: 270px;
  }

  .comment-container h5 {
    color: var(--color-white);
    padding-block: 0.3em;
  }
}
