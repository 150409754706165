@import "../../index.css";

.contact-container {
  background-color: var(--color-bgGrey);
  max-width: 100%;
  min-height: 100dvh;
  width: 100svw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-block: 2em;
}

.bottom-container {
  display: grid;
  grid-template-columns: 33dvw 33dvw;
  grid-template-rows: 1;
  column-gap: 1rem;
}

.title {
  color: var(--color-white);
}

@media screen and (max-width: 768px) {
  .bottom-container {
    grid-template-rows: 2;
  }

  .sahibinden {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    justify-self: stretch;
  }

  .letgo {
    grid-row-start: 2;
    justify-self: stretch;
  }
}
