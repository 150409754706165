.phone-card {
  width: 85vw;
  outline: 1px solid grey;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  height: fit-content;
}

.fiyat {
  color: var(--color-darkYellow);
  font-size: 20px;
}

.image {
  width: 15%;
  height: 100%;
  align-self: center;
}

.phone-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.phone-model {
  color: var(--color-whiteGrey);
  font-size: 14px;
}

.phone-baslik {
  color: var(--color-white);
  font-size: 16px;
}

.hr {
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  margin-inline: 5px;
  background-color: grey;
  height: 1.5px;
}

@media screen and (max-width: 768px) {
  .image {
    width: 150px;
  }
  .phone-baslik {
    font-size: 12px;
  }
  .phone-model {
    font-size: 10px;
  }
  .fiyat {
    font-size: 12px;
  }
}
