@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap");

:root {
  --color-gold: #cf9a44;
  --color-black: #1e1c1c;
  --color-white: #ffffff;
  --color-background: #fff8ee;
  --color-grey: #7f7f80;
  --color-bgGrey: #5f5f63;
  --color-whiteGrey: #cdcdd4;
  --color-yellow: #ffe800;
  --color-pink: #ff3d54;
  --color-darkYellow: #ffc107;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Josefin Sans";
}
