.logo {
  cursor: pointer;
  position: fixed;
  width: 50px;
  height: 50px;
  top: 90%;
  left: 95%;
  transform: translate(-90%, 10%);
  z-index: 999;
}
