@import "../../index.css";

.letgo-card-container {
  height: 350px;
  background-color: var(--color-pink);
  gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding-inline: 1em;
  padding-block: 2em;
  margin-block: 2em;
}

.letgo-card-container:hover {
  outline: 2px solid var(--color-black);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
}

.letgo-container {
  height: 72px;
  width: 72px;
  border-radius: 100%;
  margin-left: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 37, 80, 0.7);
  box-shadow: 5px black;
}

.letgo {
  border-radius: 100%;
  width: 48px;
  height: 48px;
}

.title {
  color: var(--color-white);
}

.content {
  color: gainsboro;
}

.button {
  padding-inline: 2em;
  padding-block: 0.7em;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  border: solid 0.5px black;
}

.button a {
  text-decoration: none;
  color: var(--color-black);
}

@media screen and (max-width: 768px) {
  .letgo-container {
    margin-left: 0;
  }
  .letgo-card-container {
    width: 66dvw;
  }
}
