.home-container {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  padding-top: 6rem;
  gap: 0.5rem;
}

.video-container {
  max-width: 100%;
  width: 100%;
  height: auto;
  z-index: -999;
  position: fixed;
}

.video {
  width: 100%;
  min-height: 100vh;
  filter: grayscale(1) brightness(0.6);
  object-fit: cover;
}

.phones-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 3;
  height: 95%;
  padding: 1rem;
  grid-row-start: 2;
  gap: 0.5rem;
}

.data-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

.pagination-container {
  display: block;
}

.filters-btns-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-block: 1rem;
}

.filters-btns-container .btn {
  width: 100%;
  padding-block: 1rem;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
  background-color: transparent;
}

.accordion-button {
  color: yellow;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
}

.accordion-body {
  max-height: 300px;
  overflow-y: auto;
}

.filter-container {
  background-color: black;
  padding: 1rem;
  padding-top: 3rem;
  position: absolute;
  overflow-y: scroll;
  top: 0;
  display: grid;
  align-items: flex-end;
  height: 100%;
  min-width: 300px;
  max-width: 20vw;
  grid-row-start: 2;
  transition: max-width 0.3s ease-in-out;
  z-index: 1;
}

.filter-container-toggled {
  display: none;
}

.filter-menu {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 300px;
  width: 25px;
  height: 40px;
  gap: 8px;
  padding: 1rem;
  display: grid;
  padding-left: 0.3rem;
  align-items: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: grey;
  z-index: 999;
}

.filter-menu-toggled {
  z-index: 999;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  width: 25px;
  height: 40px;
  gap: 8px;
  padding: 1rem;
  display: grid;
  padding-left: 0.3rem;
  align-items: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: grey;
}

.filter-menu-toggled .line-1 {
  transform: rotate(45deg);
}
.filter-menu-toggled .line-2 {
  transform: rotate(-45deg);
}

.line-1 {
  height: 2px;
  width: 15px;
  background-color: white;
  border-radius: 3px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-45deg);
}

.line-2 {
  height: 2px;
  width: 15px;
  background-color: white;
  border-radius: 3px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(45deg);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--color-grey);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-darkYellow);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: yellow;
}

@media screen and (max-width: 1025px) {
  .data-container {
    padding-block: 1rem;
  }

  .phones-container {
    padding: 0;
    padding-block: 1rem;
  }
}
