@import "../../index.css";

.header-container {
  z-index: 999;
  width: 100dvw;
  height: 100px;
  max-width: 100%;
  backdrop-filter: blur(5px);
  position: fixed;
  background: transparent;
}

.logo {
  height: 70px;
  align-self: flex-start;
}

.nav-container {
  margin-inline: 5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-list {
  display: flex;
  margin-block: auto;
  align-items: center;
  justify-content: center;
  padding-block: 3em;
  gap: 2rem;
}

.nav-list-element {
  list-style: none;
}

.nav-list-element a {
  text-decoration: none;
  position: relative;
  color: var(--color-darkYellow);
  font-size: 20px;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  padding-bottom: 5px;
}

.nav-list-element a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: var(--color-darkYellow);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.nav-list-element a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hamburger {
  display: none;
}

.hamburger-container {
  display: none;
}

.hamburger-toggled {
  display: none;
}

.hamburger div {
  width: 2rem;
  height: 0.2rem;
  margin: 0.3rem auto;
  border-radius: 10px;
  transition: 0.3s ease-in;
  background: var(--color-darkYellow);
}

.hamburger-toggled div {
  width: 2rem;
  height: 0.2rem;
  margin: 0.3rem auto;
  border-radius: 10px;
  transition: 0.3s ease-in;
  background: var(--color-darkYellow);
}

.hamburger-toggled div {
  margin-block: -0.2rem;
}
.hamburger-toggled .line-1 {
  transform: rotate(45deg);
}
.hamburger-toggled .line-2 {
  display: none;
}
.hamburger-toggled .line-3 {
  transform: rotate(-45deg);
}

.menu {
  z-index: 0;
  gap: 3em;
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100dvh;
}

@media screen and (max-width: 1400px) {
  .logo {
    display: none;
  }

  .nav-list {
    gap: 1rem;
  }

  .nav-container {
    margin-inline: 1em;
  }
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .hamburger-toggled {
    display: block;
    cursor: pointer;
  }
  .hamburger-container {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 10%;
    background-color: var(--color-black);
  }
  .nav-list {
    display: none;
  }
  .nav-container {
    justify-content: space-between;
    margin-inline: 2em;
  }
  .logo {
    display: flex;
    height: 60px;
    margin-top: 1rem;
  }
}
