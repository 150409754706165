#detail-container-sm {
  display: grid;
  justify-content: center;
  grid-template-columns: 65% 35%;
  grid-template-rows: 4;
  border-radius: 15px;
  min-width: 70dvw;
  min-height: 80dvh;
  margin-inline: auto;
  margin-block: 2rem;
}

#title {
  position: relative;
  padding-top: 5rem;
}

.carousel-inner {
  width: 100%;
  height: 600px;
}

.carousel-item > img {
  border-radius: 15px;
  width: 100%;
  height: 600px;
  object-fit: contain;
}

.description-container {
  border-radius: 15px;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 300px;
  padding: 1rem;
  border: 1px solid var grey;
  color: var(--color-darkYellow);
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.phone-detail-container {
  border-radius: 15px;
  grid-row-start: 1;
  grid-column-start: 2;
  min-height: 100px;
  padding: 1rem;
  color: var(--color-darkYellow);
  border: 1px solid gray;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.phone-detail-container hr {
  margin-inline: 1rem;
}

.wp-contact-button {
  margin-top: 4rem;
  border-radius: 15px;
  cursor: pointer;
  border: solid 0.2rem gray;
  display: flex;
  padding-block: 5px;
  padding-inline: 1rem;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
}

.wp-contact-button:hover {
  border-color: white;
}

a {
  text-decoration: none;
}

.get-contact-text {
  padding-top: 1rem;
  text-decoration: none;
  color: white;
  font-size: 24px;
}

#indicatorContainer {
  display: grid;
  place-items: center;
  background-color: white;
  padding: 0.3rem;
  width: 100%;
}

#indicatorImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#indicatorBtn {
  padding: 0;
  height: 35px;
  width: 100%;
}

#updatePhoneLink .btn {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  #detail-container-sm {
    grid-template-columns: 100%;
  }
  .phone-detail-container {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .phone-detail-container hr {
    margin-inline: 1rem;
  }

  .description-container {
    grid-row-start: 3;
    min-height: 50%;
    padding-inline: 1rem;
  }
}

@media screen and (max-width: 758px) {
  #title {
    padding-top: 0;
    padding-bottom: 0;
    top: 4rem;
  }
}

@media screen and (max-width: 558px) {
  #title {
    padding-top: 0;
    padding-bottom: 0;
    top: 8rem;
  }
}
