@import "../../index.css";

.top-phones-container {
  background-color: var(--color-bgGrey);
  width: 100svw;
  min-height: 100dvh;
  max-width: 100%;
  padding-block: 2em;
  display: flex;
  margin-inline: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.phones-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 3;
  height: 95%;
  padding: 1rem;
  grid-row-start: 2;
  gap: 0.5rem;
}

.data-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
}

.title {
  color: var(--color-darkYellow);
  margin-block: 1em;
}

@media screen and (max-width: 1025px) {
  .data-container {
    padding-block: 1rem;
  }

  .phones-container {
    padding: 0;
    padding-block: 1rem;
  }
}
