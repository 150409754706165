@import "../../index.css";

.home-container {
  background-color: var(--color-black);
  max-width: 100%;
  width: 100svw;
  min-height: 100dvh;
}

.video-container {
  max-width: 100%;
  width: 100%;
  height: 100svh;
}

.video {
  width: 100%;
  height: 100%;
  filter: grayscale(1) brightness(0.6);
  object-fit: cover;
}

.content-container {
  position: absolute;
  display: grid;
  text-align: center;
  place-items: center;
  gap: 1rem;
  top: 30%;
  left: 50%;
  width: 100dvw;
  max-width: 100%;
  transform: translate(-50%, 10%);
}

.span-text {
  text-align: center;
  line-height: 3rem;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 3px;
  color: var(--color-white);
}

.span-text-title {
  font-weight: 800;
  letter-spacing: 5px;
}

.phones-btn {
  cursor: pointer;
  display: block;
  position: relative;
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  padding: 1rem 2.5rem;
  font-weight: 200;
  letter-spacing: 3px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.35s;
  text-align: center;
}

.phones-btn a {
  text-decoration: none;
  color: var(--color-white);
}

.phones-btn span {
  position: relative;
  z-index: 2;
}

.phones-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--color-black);
  transition: all 0.35s;
}

.phones-btn:hover {
  color: #ffffff;
}

.phones-btn:hover:after {
  width: 100%;
}
