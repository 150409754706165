@import "../../index.css";

.sahibinden-card-container {
  height: 350px;
  background-color: var(--color-yellow);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  border-radius: 10px;
  padding-inline: 1em;
  padding-block: 2em;
  margin-block: 2em;
}

.sahibinden-card-container:hover {
  outline: 2px solid var(--color-black);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
}

.sahibinden-container {
  height: 72px;
  width: 72px;
  border-radius: 100%;
  margin-left: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 238, 73, 0.7);
  box-shadow: 5px black;
}

.sahibinden {
  border-radius: 100%;
  width: 48px;
  height: 48px;
}

.title {
  color: var(--color-black);
}

.content {
  color: gainsboro;
}

.button {
  padding-inline: 2em;
  padding-block: 0.7em;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  border: solid 0.5px black;
}

.button a {
  text-decoration: none;
  color: var(--color-black);
}

@media screen and (max-width: 768px) {
  .sahibinden-container {
    margin-left: 0;
  }
  .sahibinden-card-container {
    width: 66dvw;
  }
}
