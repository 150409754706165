@import "../../index.css";

.footer-container {
  background-color: var(--color-black);
  width: 100svw;
  max-width: 100%;
  min-height: 50dvh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-block: 1em;
}

.map-container {
  flex: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  overflow: hidden;
  height: 80%;
  margin: 1rem;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  justify-content: space-evenly;
  align-items: flex-start;
}

.contact-container {
  display: flex;
  flex-direction: column;
}

.navigation-container {
  display: flex;
  flex-direction: column;
}

.social-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.icons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.map-container iframe {
  border: 0;
  border-radius: 10px;
}

p {
  color: var(--color-grey);
}

.title {
  color: var(--color-white);
  margin-bottom: 1rem;
}

.navigation-container a {
  text-decoration: none;
  color: var(--color-grey);
}

.social-icons {
  width: 50px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .map-container {
    flex: 100%;
    align-items: center;
  }
  .navigation-container {
    flex: 100%;
    align-items: center;
    margin-block: 0.5rem;
  }
  .contact-container {
    flex: 100%;
    align-items: center;
    margin-block: 0.5rem;
  }
  .social-container {
    flex: 100%;
    align-items: center;
    margin-block: 0.5rem;
  }
}
